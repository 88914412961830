import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

import { CurrentOrganizationSubscriptionFeatureLimit } from '@bp/shared/domains/organizations';

@Component({
	selector: 'bp-current-organization-subscription-feature-limit-meter',
	templateUrl: './current-organization-subscription-feature-limit-meter.component.html',
	styleUrls: [ './current-organization-subscription-feature-limit-meter.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class CurrentOrganizationSubscriptionFeatureLimitMeterComponent {
	@Input() featureLimit!: CurrentOrganizationSubscriptionFeatureLimit;

	@Input() reflectWarning!: boolean;

	@HostBinding('class.warning')
	get isWarningThresholdReached(): boolean {
		return this.reflectWarning && this.featureLimit.isWarningThresholdReached;
	}

	@HostBinding('class.danger')
	get isDangerThresholdReached(): boolean {
		return this.reflectWarning && this.featureLimit.isDangerThresholdReached;
	}
}

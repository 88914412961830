import { map, startWith } from 'rxjs';

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { SupportAgentDetectorService } from '@bp/frontend/services/core';
import { CurrentCrmOrganizationFacade } from '@bp/frontend/domains/crm/organizations/+current-crm-organization-state';
import { FADE, FADE_IN, ROUTE_HIT_ANIMATIONS } from '@bp/frontend/animations';
import { MerchantAdminDesignState } from '@bp/frontend/services/persistent-state-keepers';
import { EnvironmentService } from '@bp/frontend/services/environment';

import { LayoutFacade } from '@bp/admins-shared/features/layout';
import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { CurrentOrganizationSubscriptionFacade } from '@bp/merchant-admin-shared/frontend/domains/current-organization-subscription';
import { CurrentOrganizationFacade } from '@bp/merchant-admin-shared/frontend/domains/current-organization';
import {
	CurrentOrganizationSubscriptionModule
} from '@bp/merchant-admin/frontend/domains/current-organization-subscription';
import { LoginToOrganizationComponent } from '@bp/merchant-admin-shared/frontend/shared/components';

import { CoreModule } from '../../core.module';

@Component({
	selector: 'bp-root',
	standalone: true,
	imports: [
		CoreModule,
		CurrentOrganizationSubscriptionModule,
		LoginToOrganizationComponent,
	],
	templateUrl: './root.component.html',
	styleUrls: [ './root.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ ROUTE_HIT_ANIMATIONS, FADE, FADE_IN ],
	host: {
		class: 'mat-typography',
	},
})
export class RootComponent {

	protected readonly _isSupportAgent = inject(SupportAgentDetectorService).isSupportAgent;

	protected readonly _layoutFacade = inject(LayoutFacade);

	protected readonly _identityFacade = inject(IdentityFacade);

	protected readonly _environment = inject(EnvironmentService);

	protected readonly _currentOrganizationFacade = inject(CurrentOrganizationFacade);

	protected readonly _currentCrmOrganizationFacade = inject(CurrentCrmOrganizationFacade);

	protected readonly _currentOrganizationSubscriptionFacade = inject(CurrentOrganizationSubscriptionFacade);

	protected _shouldAnimateOutlet!: boolean;

	protected readonly _MerchantAdminFeature = MerchantAdminFeature;

	protected _isMainNavigationEnabled$
		= this._currentOrganizationSubscriptionFacade.isMustPurchaseSubscriptionPlan$.pipe(
			map(isMustPurchaseSubscriptionPlan => !isMustPurchaseSubscriptionPlan),
			startWith(true),
		);

	protected readonly _MerchantAdminDesignState = MerchantAdminDesignState;

}

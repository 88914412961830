<bp-current-organization-subscription-feature-limit-meter
	[featureLimit]="featureLimitsMap.get('transactions')!"
	[reflectWarning]="true"
/>

<bp-current-organization-subscription-feature-limit-meter [featureLimit]="featureLimitsMap.get('merchantPsps')!" />

<bp-current-organization-subscription-feature-limit-meter [featureLimit]="featureLimitsMap.get('checkouts')!" />

<bp-current-organization-subscription-feature-limit-meter [featureLimit]="_creditPackageFeatureLimit()!" />

<bp-pending-btn
	*bpHasAccess="_MerchantAdminFeature.billing"
	type="button"
	class="mt-2 w-100"
	btnClass="bp-round-button bp-button-md mat-flat-button w-100"
	[pending]="_refillFacade.pending$ | async"
	(click)="_refillFacade.requestRefill()"
>
	Refill Your Plan
</bp-pending-btn>

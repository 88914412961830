import { Component, ChangeDetectionStrategy, Input, input, computed, inject } from '@angular/core';

import {
	CurrentOrganizationSubscription, CurrentOrganizationSubscriptionFeatureLimit
} from '@bp/shared/domains/organizations';
import { MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { MerchantCreditPackage } from '@bp/merchant-admin-shared/shared/domains/credit-packages';
import { RefillFacade } from '@bp/merchant-admin-shared/frontend/domains/credit-packages';

@Component({
	selector: 'bp-current-subscription-limits-meter-popup',
	templateUrl: './current-subscription-limits-meter-popup.component.html',
	styleUrls: [ './current-subscription-limits-meter-popup.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class CurrentSubscriptionLimitsMeterPopupComponent {

	protected readonly _refillFacade = inject(RefillFacade);

	@Input() currentOrganizationSubscription!: CurrentOrganizationSubscription;

	creditPackage = input<MerchantCreditPackage | null>();

	protected readonly _MerchantAdminFeature = MerchantAdminFeature;

	protected readonly _creditPackageFeatureLimit = computed(() => {
		const creditPackage = this.creditPackage();

		return new CurrentOrganizationSubscriptionFeatureLimit({
			limit: creditPackage?.bought ?? 0,
			counter: creditPackage?.used ?? 0,
			featureLabel: 'Data Tank',
		});
	});

	get featureLimitsMap(): CurrentOrganizationSubscription['featureLimitsMap'] {
		return this.currentOrganizationSubscription.featureLimitsMap;
	}

}

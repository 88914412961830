import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import {
	CurrentOrganizationSubscription,
	CurrentOrganizationSubscriptionFeatureLimit
} from '@bp/shared/domains/organizations';
import { SubscriptionPlan } from '@bp/shared/domains/subscription-plans/core';

@Component({
	selector: 'bp-current-subscription-indicator-button',
	templateUrl: './current-subscription-indicator-button.component.html',
	styleUrls: [ './current-subscription-indicator-button.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class CurrentSubscriptionIndicatorButtonComponent {
	@Input() currentOrganizationSubscription!: CurrentOrganizationSubscription;

	@Input() isLimitsMeterPopupOpen!: boolean;

	get currentSubscriptionPlan(): SubscriptionPlan {
		return this.currentOrganizationSubscription.subscriptionPlan;
	}

	get transactionsLimit(): CurrentOrganizationSubscriptionFeatureLimit {
		return this.currentOrganizationSubscription.featureLimitsMap.get('transactions')!;
	}

	get isWarningOrDangerThresholdReached(): boolean {
		return this.transactionsLimit.isWarningThresholdReached || this.transactionsLimit.isDangerThresholdReached;
	}
}

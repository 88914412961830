import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { FADE, FADE_IN } from '@bp/frontend/animations';

import {
	CurrentOrganizationSubscriptionFacade
} from '@bp/merchant-admin-shared/frontend/domains/current-organization-subscription';
import { CreditPackageFacade } from '@bp/merchant-admin-shared/frontend/domains/credit-packages';

@Component({
	selector: 'bp-current-subscription-limits-meter',
	templateUrl: './current-subscription-limits-meter.component.html',
	styleUrls: [ './current-subscription-limits-meter.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN, FADE ],
	standalone: false,
})
export class CurrentSubscriptionLimitsMeterComponent {

	protected readonly _currentOrganizationSubscriptionFacade = inject(CurrentOrganizationSubscriptionFacade);

	protected readonly _creditPackageFacade = inject(CreditPackageFacade);

	isLimitsMeterPopupOpen = false;

	popupPosition: ConnectedPosition[] = [
		{
			originX: 'center',
			originY: 'bottom',
			overlayX: 'center',
			overlayY: 'top',
		},
	];

}

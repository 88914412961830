import { NgModule } from '@angular/core';
import { AsyncPipe, NgIf, PercentPipe } from '@angular/common';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { RouterLink } from '@angular/router';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';

import { SharedDomainsIdentityModule } from '@bp/frontend-domains-identity';

import {
	CurrentSubscriptionLimitsMeterComponent,
	CurrentSubscriptionLimitsMeterPopupComponent,
	CurrentSubscriptionIndicatorButtonComponent,
	CurrentOrganizationSubscriptionFeatureLimitMeterComponent
} from './components';

const exposedComponents = [ CurrentSubscriptionLimitsMeterComponent ];

@NgModule({
	imports: [
		NgIf,
		AsyncPipe,
		RouterLink,
		CdkOverlayOrigin,
		CdkConnectedOverlay,
		MatLegacyButtonModule,

		PercentPipe,

		SharedComponentsCoreModule,
		SharedFeaturesTooltipModule,
		SharedDomainsIdentityModule,
	],
	declarations: [
		exposedComponents,

		CurrentSubscriptionIndicatorButtonComponent,
		CurrentSubscriptionLimitsMeterPopupComponent,
		CurrentOrganizationSubscriptionFeatureLimitMeterComponent,
	],
	exports: [ exposedComponents, CurrentSubscriptionIndicatorButtonComponent ],
})
export class CurrentOrganizationSubscriptionModule {}
